
.App {
  text-align: center;
}

body {
  height: 100vh;
  font-family: 'Share Tech Mono';
}

.waveform-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.waveform {
  position: relative;
}

.notes {
  display: flex;
}

.circle {
  position: absolute;
  bottom: 0;
  left: 2rem;
}

.rectangle {
  position: absolute;
  top: 0rem;
  left: 40%;
}

.triangle {
  position: absolute;
  top: 20%;
  right: 0;
}

.content-hero {
  position: absolute;
  width: 100%;
  top: 0;
  color: white;
  display: flex;
  height: 100vh;
}
.contact {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.hero-text {
  font-size: 4.8rem;
}
